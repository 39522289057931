import React from "react";
import { Navbar, Banner } from "../../components";
import { Contact } from "../../containers";

// import "./Project02.scss";
import images from "../../constants/images";
import Preloader2 from "../../components/PreLoader2/Preloader2";

const Project02 = () => {
  return (
    <div className="app__project01">
      <Preloader2 />
      <Banner />
      <Navbar />

      <h4 className="phone-h4 h4 mx-hide">BRANDING: ONEONEONE™</h4>
      <div className="phone-proj-header hide">
        <h4 className="h4 hide">BRANDING: ONEONEONE™</h4>
      </div>
      <img src={images.thumbnail} alt="oneoneone" className="proj-thumbnail" />

      <p className="brief proj-text mx-hide">
        <b>ONEONEONE</b>, also stylized as "111", constitutes a
        multi-dimensional community that utilizes various media to communicate.
        Our discourse transcends beyond the mere use of text, music, literature,
        podcasts, television, film, and art. Sometimes, it becomes essential to
        adopt diverse communication forms to convey a message or emotion
        effectively. Our ultimate objective is to disseminate stories, numerous
        stories that resonate with individuals from diverse cultures, races, age
        groups, and personalities.
      </p>
      <p className="brief phone-proj-text hide">
        <b>ONEONEONE</b>, also stylized as "111", constitutes a
        multi-dimensional community that utilizes various media to communicate.
        Our discourse transcends beyond the mere use of text, music, literature,
        podcasts, television, film, and art. Sometimes, it becomes essential to
        adopt diverse communication forms to convey a message or emotion
        effectively. Our ultimate objective is to disseminate stories, numerous
        stories that resonate with individuals from diverse cultures, races, age
        groups, and personalities.
      </p>

      <div className="showcase-container">
        <img src={images.posts} className="proj-media" alt="oneoneone"></img>
        <video
          src={images.recordvid}
          className="proj-media"
          autoPlay
          alt="oneoneone"
        ></video>
      </div>

      <p className="proj-text mx-hide">
        <b>LOGO</b> Meticulously fashioned, the logo is in my opinion
        captivating enough without overshadowing the essence of the work.
        Noteworthy is the subtle quirkiness embedded in the letter 'e.' The
        conceptual genesis of the name draws inspiration from my angelic
        encounter with the number 111. This numeral holds profound significance
        to myself and many others.
      </p>
      <p className="phone-proj-text hide">
        <b>LOGO</b> Meticulously fashioned, the logo is in my opinion
        captivating enough without overshadowing the essence of the work.
        Noteworthy is the subtle quirkiness embedded in the letter 'e.' The
        conceptual genesis of the name draws inspiration from my angelic
        encounter with the number 111. This numeral holds profound significance
        to myself and many others.
      </p>

      <div className="showcase-container">
        <img src={images.door} className="proj-media" alt="oneoneone"></img>
      </div>

      <div className="last-text-box">
        <p className="proj-text mx-hide">
          <b>TYPOGRAPHY + COLOR</b> In crafting this presentation, I've embraced
          the elegant strokes of Helvetica Bold (sans serif) and the refined
          character of Garamond narrow (serif). Delving into a monochromatic
          palette, I've opted for a nuanced approach, steering clear of pure
          blacks and whites. Instead, I've chosen an off-white tone for a softer
          white and a more subdued black.
        </p>
        <p className="phone-proj-text hide">
          <b>TYPOGRAPHY + COLOR</b> In crafting this presentation, I've embraced
          the elegant strokes of Helvetica Bold (sans serif) and the refined
          character of Garamond narrow (serif). Delving into a monochromatic
          palette, I've opted for a nuanced approach, steering clear of pure
          blacks and whites. Instead, I've chosen an off-white tone for a softer
          white and a more subdued black.
        </p>

        <div className="proj-links">
          <div className="proj-link">
            <a href="https://dribbble.com/shots/23107990--2023-OneOneOne">
              Dribble
            </a>{" "}
            <span className="mx-hide">01</span>
          </div>
          {/* <div className="proj-link">
            <a href="">Brand Video</a> <span className="mx-hide">02</span>
          </div> */}
        </div>
      </div>

      <hr />

      <footer className="proj-footer">
        <a href="/#projects" className="go-back-btn">
          ← Go back
        </a>
        <p className="proj-footer-text">Liam Melkersson ©2025</p>
      </footer>

      <Contact />
    </div>
  );
};

export default Project02;
