import React from "react";
import { Navbar, Banner } from "../../components";
import { Contact } from "../../containers";

// import "./Project02.scss";
import images from "../../constants/images";
import Preloader2 from "../../components/PreLoader2/Preloader2";

const Project02 = () => {
  return (
    <div className="app__project01">
      <Preloader2 />
      <Banner />
      <Navbar />

      <h4 className="phone-h4 h4 mx-hide">AWAIO WEB — (RE)DESIGN</h4>
      <div className="phone-proj-header hide">
        <h4 className="h4 hide">AWAIO WEB — (RE)DESIGN</h4>
      </div>
      <img src={images.awaiothumbnail} alt="" className="proj-thumbnail" />
      {/* <video
        alt="awaio video"
        src={images.awaio2}
        className="proj-thumbnail"
        autoPlay="true"
        loop
      ></video> */}

      <p className="brief proj-text mx-hide">
        <em>*ongoing project*</em> <br />
        <b>AWAIO</b> is a hybrid workplace solution designed to enhance
        efficiency and collaboration by enabling sharing and management of
        office resources. Their vision is to create a more flexible and dynamic
        work life while taking care of the world we live in. The platform is
        100% scalable, catering to corporations of all sizes, multi-tenant
        office buildings, coworking spaces, and university campuses. Trusted by
        leading companies such as Volvo, Sweco, and Intrum, Awaio is rapidly
        expanding in their efforts of setting the office hybrid working
        standard.
      </p>
      <p className="brief phone-proj-text hide">
        <em>*ongoing project*</em> <br />
        <b>AWAIO</b> is a hybrid workplace solution designed to enhance
        efficiency and collaboration by enabling sharing and management of
        office resources. Their vision is to create a more flexible and dynamic
        work life while taking care of the world we live in. The platform is
        100% scalable, catering to corporations of all sizes, multi-tenant
        office buildings, coworking spaces, and university campuses. Trusted by
        leading companies such as Volvo, Sweco, and Intrum, Awaio is rapidly
        expanding in their efforts of setting the office hybrid working
        standard.
      </p>

      <div className="showcase-container">
        <img src={images.awaioproj1} className="proj-media" alt="junya"></img>
        <img src={images.awaioproj2} className="proj-media" alt="junya"></img>
      </div>

      <p className="proj-text mx-hide">
        One of the main <b>CHALLENGES</b> I’ve faced while designing and
        rebuilding Awaio’s website is the difficulty of branding such a
        versatile, all-in-one solution. Its flexibility and wide range of
        benefits and features makes it really hard to portray their package into
        a concise, compelling story. Another challenge/finding has been working
        with an iterative design process. As both the company and my
        understanding of the product evolve rapidly, We’ve had to constantly
        adapt and refine the design, embracing change as a natural part of the
        process.
      </p>
      <p className="phone-proj-text hide">
        One of the main <b>CHALLENGES</b> I’ve faced while designing and
        rebuilding Awaio’s website is the difficulty of branding such a
        versatile, all-in-one solution. Its flexibility and wide range of
        benefits and features makes it really hard to portray their package into
        a concise, compelling story. Another challenge/finding has been working
        with an iterative design process. As both the company and my
        understanding of the product evolve rapidly, We’ve had to constantly
        adapt and refine the design, embracing change as a natural part of the
        process.
      </p>

      {/* <div className="showcase-container">
        <img src={images.typography} className="proj-media" alt="junya"></img>
      </div> */}
      {/* <video
        alt="awaio video"
        src={images.awaiovid}
        className="proj-media"
        loop
      ></video> */}

      <div className="last-text-box">
        {/* <p className="proj-text">
          <b>TYPOGRAPHY + COLOR</b> In crafting this presentation, I've embraced
          the elegant strokes of Helvetica Bold (sans serif) and the refined
          character of Garamond narrow (serif). Delving into a monochromatic
          palette, I've opted for a nuanced approach, steering clear of pure
          blacks and whites. Instead, I've chosen an off-white tone for a softer
          white and a more subdued black.
        </p> */}

        <div className="proj-links">
          <div className="proj-link">
            <a href="https://www.awaio.com">Awaio.com</a>{" "}
            <span className="mx-hide">01</span>
          </div>
          {/* <div className="proj-link">
            <a href="https://drive.google.com/uc?export=download&id=1PrN7fiKSwjELhH5Lwm_tve6Imo0srFDi">
              Visual Identity
            </a>{" "}
            <span className="mx-hide">02</span>
          </div> */}
        </div>
      </div>

      <hr />

      <footer className="proj-footer hide">
        <a href="/#projects" className="go-back-btn">
          ← Go back
        </a>
        <p className="proj-footer-text">Liam Melkersson ©2025</p>
      </footer>

      <Contact />
    </div>
  );
};

export default Project02;
