import React from "react";
import { Navbar, Banner } from "../../components";
import { Contact } from "../../containers";

import "./Project01.scss";
import images from "../../constants/images";
import Preloader2 from "../../components/PreLoader2/Preloader2";

const Project01 = () => {
  return (
    <div className="app__project01">
      <Preloader2 />
      <Banner />
      <Navbar />

      <h4 className="phone-h4 h4 mx-hide">Stammy Landing Page</h4>
      <div className="phone-proj-header hide">
        <h4 className="h4 hide">Stammy Landing Page</h4>
      </div>
      <video
        alt="stammy video"
        src={images.stammy}
        className="proj-thumbnail"
        autoPlay="true"
        loop
      ></video>

      <p className="brief proj-text mx-hide">
        <b></b>This landing page was done during my Internship at Papaja for — a
        loyalty app called Stammy. The app let's customers collect bonuses and
        pay in advance for places like resturants. I designed the landing page
        in Figma, with guidelines from the company and tried to adapt the design
        to fit the vibe and functionality of the app. The website was then later
        developed with the help of Laravel Blade and the static site generator
        Jigsaw.
      </p>
      <p className="brief phone-proj-text hide">
        <b></b>This landing page was done during my Internship at Papaja for — a
        loyalty app called Stammy. The app let's customers collect bonuses and
        pay in advance for places like resturants. I designed the landing page
        in Figma, with guidelines from the company and tried to adapt the design
        to fit the vibe and functionality of the app. The website was then later
        developed with the help of Laravel Blade and the static site generator
        Jigsaw.
      </p>

      <div className="showcase-container">
        <img src={images.stammy1} className="proj-media" alt="stammy"></img>
        <img src={images.stammy2} className="proj-media" alt="stammy"></img>
      </div>

      {/* <p className="proj-text">
        <b>STRUCTURE</b> The Steam desktop app, or the Steam client, is
        comprised of two main components. The first part includes the native
        client features such as navigation, downloads, settings, and more. The
        second part is the Steam website, which is hosted within the Steam
        client to display content for various pages. This is primarily why Steam
        exhibits some inconsistencies, as the client is updated occasionally,
        but the website remains relatively unchanged and somewhat stuck in the
        2010s. To address this, the idea is to merge these two components to
        conceal desktop-specific features when using the browser.
      </p>

      <div className="showcase-container">
        <img src={images.store} className="proj-media"></img>
        <img src={images.games} className="proj-media"></img>
      </div> */}

      <div className="last-text-box">
        {/* <p className="proj-text">
          <b>NAVIGATION</b> The most pressing issue with the Steam client is the
          chaotic placement of elements. Users struggle to locate even the most
          basic functions, such as redeeming a gift card within the Steam app.
          There are six regions with distinct options and menus, and many of
          these options are repetitive. For instance, "Friends and Chat" appears
          here, here, and here. To streamline this, I've compiled all the
          options from these six regions, created top-level sections, organized
          the options among them, and removed the redundant lower part. This
          way, we can eliminate the unnecessary clutter, consolidate all
          essential parts of Steam into one line, and place "Big Picture Mode"
          and "Small Mode" inside a drop-down menu to prevent accidental clicks,
          as very few people actually use them.
        </p> */}

        <div className="proj-links">
          <div className="proj-link">
            <a href="https://dribbble.com/shots/24329966-Stammy-Landing-Page">
              Dribble
            </a>
            <span className="mx-hide">01</span>
          </div>
          <div className="proj-link">
            <a href="https://stammy.se/">stammy.se</a>{" "}
            <span className="mx-hide">02</span>
            {/* <p className="mx-hide">**Site might not be live yet</p> */}
          </div>
          {/* <div className="proj-link">
            <a href="">Figma Design File</a> 03
          </div>
          <div className="proj-link">
            <a href="">Steam Brand Guidelines</a> 04
          </div> */}
        </div>
      </div>

      <hr />

      <footer className="proj-footer">
        <a href="/#projects" className="go-back-btn">
          ← Go back
        </a>
        <p className="proj-footer-text">Liam Melkersson ©2025</p>
      </footer>

      <Contact />
    </div>
  );
};

export default Project01;
