import React from "react";

import "./Contact.scss";
import { images } from "../../constants";

const Contact = () => {
  return (
    <section className="app__contact" id="contact">
      <div className="contact-links mx-flex-dir">
        <div className="contact-column-1">
          <div className="contact-link mail">
            <h3>
              <a href="mailto:liammelkersson@live.se">MAIL</a>
            </h3>
            <p>01</p>
          </div>
          <div className="contact-link phone">
            <h3>
              <a href="tel:0761634425">PHONE</a>
            </h3>
            <p>02</p>
          </div>
          <div className="contact-link cv">
            <h3>
              <a href={images.cv} download="">
                CV
              </a>
            </h3>
            <p>03</p>
          </div>
        </div>
        <div className="contact-column-2">
          <div className="contact-link linkedin">
            <h3>
              <a
                href="https://linkedin.com/in/liammelkersson"
                target="_blank"
                id="linkedin"
              >
                LINKEDIN
              </a>
            </h3>
            <p>04</p>
          </div>
          <div className="contact-link github">
            <h3>
              <a
                href="https://github.com/liammelkersson"
                target="_blank"
                id="github"
              >
                GITHUB
              </a>
            </h3>
            <p>05</p>
          </div>
          <div className="contact-link dribble">
            <h3>
              <a
                href="https://dribbble.com/liammelkersson"
                target="_blank"
                id="dribbble"
              >
                DRIBBBLE
              </a>
            </h3>
            <p>06</p>
          </div>
        </div>
        {/* <img src={images.me} alt="me myself and i" className="img-me-backup" /> */}
        {/* <iframe
          className="spotify"
          src="https://open.spotify.com/embed/playlist/1QvHbJJ0a7tto2pKQD8dQs?utm_source=generator"
          allowfullscreen=""
          width="300"
          height="300"
          frameBorder=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe> */}
      </div>

      <p className="contact-p">
        Thank you for visiting my portfolio. I value your feedback, questions,
        and inquiries. Please don't hesitate to reach out, hit me up!
      </p>

      <div className="app__contact-header">
        <div className="mx-hide">
          <h2 className="mx-hide">DESIGNER</h2>
          <h2 className="mx-hide">DEVELOPER</h2>
        </div>
        <h1 className="mx-hide">LIAM MELKERSSON</h1>
        <footer>
          <span>Liam Melkersson ©2025</span>
        </footer>
      </div>
    </section>
  );
};

export default Contact;
